<template>
  <v-hover v-slot:default="{ hover }">
    <v-card :elevation="hover ? 5 : 2" class="mx-auto">
      <v-card-text class="pa-0">
        <v-container fluid>
          <v-row no-gutters align="center" class="mb-2">
            <v-col class="pl-2" cols="7">
              <v-row>
                <v-col>
                  <div class="dashboard-widget-title">{{widgetItems.title}}</div>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col v-if="typeof widgetItems.value === 'object'">
                  <div class="dashboard-widget-value2 pb-3">{{widgetItems.value.key}}</div>
                  <div class="dashboard-widget-value2">${{widgetItems.value.amount}}</div>
                </v-col>
                <v-col v-else>
                  <div class="dashboard-widget-value">{{widgetItems.value}}</div>
                </v-col>
              </v-row>
            </v-col>
            <v-col align="end" cols="5">
              <div>
                <v-icon v-bind:color="widgetItems.color" class="dashboard-widget-icon">{{widgetItems.icon}}</v-icon>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-divider/>
        <div>
          <v-tooltip bottom v-if="widgetItems.action">
            <template v-slot:activator="{ on }">
              <v-btn
                  large
                  class="icon-text widget-btn"
                  :to="widgetItems.action"
                  v-on="on"
              >
                <v-icon right>near_me</v-icon>
              </v-btn>
            </template>
            <span>{{ 'Click to see ' + widgetItems.title + ' list' }}</span>
          </v-tooltip>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on }">
              <v-btn
                      large
                      class="icon-text widget-btn"
                      v-on="on"
              >
                <v-icon right>near_me</v-icon>
              </v-btn>
            </template>
            <span>{{ widgetItems.title }}</span>
          </v-tooltip>
        </div>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: "DashboardWidget",
  props: {
    widgetItems: {
      type: Object,
      default: function () {
        return {};
      }
    }
  }
}
</script>

<style scoped lang="scss">
.icon-text {
  font-size: 60px;
  color: #168FFB;

  .v-icon {
    font-size: 24px !important;
  }
}

.widget-btn {
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: transparent !important;
  box-shadow: none !important;
}

.dashboard-widget-title {
  /*color: #373d3f !important;*/
  color: #344050 !important;
  font-size: 1.25rem !important;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
}
.dashboard-widget-value {
  line-height: 1;
  color: #5e6e82!important;
  font-size: 2.2rem;
  font-weight: 400!important;
}

.dashboard-widget-value2 {
  line-height: 1;
  color: #5e6e82!important;
  font-size: 1.2rem;
  font-weight: 400!important;
}

.theme--light{
  .dashboard-widget-title {
    color: #344050 !important;
  }
  .dashboard-widget-value {
    color: #5e6e82!important;
  }

  .dashboard-widget-value2 {
    color: #5e6e82!important;
  }
}
.theme--dark {
  .dashboard-widget-title {
    color: rgb(246, 247, 248) !important;
  }
  .dashboard-widget-value {
    color: rgb(246, 247, 248) !important;
  }

  .dashboard-widget-value2 {
    color: rgb(246, 247, 248) !important;
  }
}
.dashboard-widget-icon {
  font-size: 70px !important;
}
.dashboard-widget-anchor {
  font-weight: 600!important;
  font-size: .83333rem;
}
</style>